import React from "react";
import './HomePage.css';

import {Icon, Paper} from "@mui/material";

// Banners
import brooks_large from "../../images/banners/4titude/Bandeau-4titude-1160x330.jpg";
import brooks_medium from "../../images/banners/4titude/Bandeau-4titude-770x330.jpg";
import brooks_small from "../../images/banners/4titude/Bandeau-4titude-375x330.jpg";
import bioline_large from "../../images/banners/bioline/Bandeau-Bioline-1160x330.jpg";
import bioline_medium from "../../images/banners/bioline/Bandeau-Bioline-770x330.jpg";
import bioline_small from "../../images/banners/bioline/Bandeau-Bioline-375x330.jpg";
import biosan_large from "../../images/banners/biosan/Bandeau-Biosan-1160x330.jpg";
import biosan_medium from "../../images/banners/biosan/Bandeau-Biosan-770x330.jpg";
import biosan_small from "../../images/banners/biosan/Bandeau-Biosan-375x330.jpg";
import seval_large from "../../images/banners/seval/Bandeau-Verrerie-1160x330.jpg";
import seval_medium from "../../images/banners/seval/Bandeau-Verrerie-770x330.jpg";
import seval_small from "../../images/banners/seval/Bandeau-Verrerie-375x330.jpg";

// Brochures
import biosan_brochure_image from "../../images/catalogues/2021/biosan/biosan_2021_05.png";
import biosan_brochure_pdf from "../../images/catalogues/2021/biosan/biosan_2021.pdf";
import bioline_brochure_image from "../../images/catalogues/2021/bioline/bioline_2020.png";
import bioline_brochure_pdf from "../../images/catalogues/2021/bioline/bioline_2021.pdf";
import phcbi_brochure_image from "../../images/catalogues/2021/phcbi/phcbi_2021.png";
import phcbi_brochure_pdf from "../../images/catalogues/2021/phcbi/phcbi_2021.pdf";
import brooks_brochure_image from "../../images/catalogues/2021/brooks/brooks_2021.png";
import brooks_brochure_pdf from "../../images/catalogues/2021/brooks/brooks_2021.pdf";
import btx_brochure_pdf from "../../images/catalogues/2024/btx/btx.pdf";
import btx_brochure_image from "../../images/catalogues/2024/btx/btx.png";
import Azenta_brochure_pdf from "../../images/catalogues/2024/azenta/azenta.pdf";
import Azenta_brochure_image from "../../images/catalogues/2024/azenta/azenta.png";

import {ExposedAPI} from "../../apis/ExposedAPI";
import ArticleList from "../../components/article/articleList/ArticleList";

export default class HomePage extends React.Component{

    constructor(props) {
        super(props);

        let carouselImages = this.getCarouselImages();

        this.state={
            carouselImages:carouselImages,
            currentCarrouselImage:carouselImages[0],
            brochures:this.getBrochures(),

            featuredArticles:[],
        }
    }

    componentDidMount(){
        this.startCarousel();
        this.loadFeaturedArticles();
    }

    componentWillUnmount(){
        this.stopCarousel()
    }

    loadFeaturedArticles(){

        ExposedAPI.getFeaturedArticles()
            .then((articles)=>{
                for(let article of articles){
                    if(article.description.length > 180){article.short_description = article.description.substring(0,180) + "..."}
                    else{article.short_description = article.description}

                    article.sort_name = article.label.toUpperCase();
                    article.sort_brand = article.brandName ? article.brandName.toUpperCase() : "";
                    article.sort_ref = article.crmId.toUpperCase();
                    article.sort_cond = article.conditionnement ? article.conditionnement.toUpperCase() : "";
                    article.sort_ht = parseFloat(article.basePrice);
                }
                this.setState({
                    featuredArticles:articles,
                })
            })
            .catch((err)=>{
                console.log(err)
            })

    }

    render(){
        return(
            <div className={"home-page-wrapper"}>
                {this.renderSimpleCarousel()}
                <div style={{height:25}}/>
                {this.renderBrochures()}
                <div style={{height:25}}/>
                {this.renderFeatured()}
                <div style={{height:25}}/>
                <Paper style={{width:"100%", padding:12}}>
                    <iframe width="100%" height="500" src="https://www.youtube.com/embed/TVeQwcipab8?controls=0&amp;start=1" title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                    style={{borderRadius:4}}/>
                </Paper>
            </div>
        )
    }

    //////////////
    // CAROUSEL //
    //////////////

    renderSimpleCarousel(){
        return(
            <div style={{height:330, width:"100%", backgroundColor:"#FAFAFA", position:"relative", borderBottomLeftRadius:6,
            borderBottomRightRadius:6, overflow:"hidden"}}>

                <div className={"carousel-image"} style={{backgroundImage:"url(" + this.state.currentCarrouselImage.image + ")"}}/>
                {this.state.currentCarrouselImage.link &&
                    <a rel={"noreferrer nofollow"} href={this.state.currentCarrouselImage.link}>
                        <div style={{width:"100%", height:330, position:"absolute", left:0, top:0, bottom:0, right:0}}>

                        </div>
                    </a>
                }

            </div>
        )
    }

    _carouselInteval;

    startCarousel(){
        if(this._carouselInteval){this.stopCarousel()}
        this._carouselInteval = setInterval(()=>{
            let nextIndex = this.state.currentCarrouselImage.id + 1;
            if(nextIndex > this.state.carouselImages.length - 1){
                nextIndex = 0
            }
            this.setState({
                currentCarrouselImage:this.state.carouselImages[nextIndex],
            })
        },10000)
    }

    stopCarousel(){
        if(this._carouselInteval){
            clearInterval(this._carouselInteval);
            this._carouselInteval = null;
        }
    }

    getCarouselImages(){
        return([
            {
                id:0,
                link:"https://www.brookslifesciences.com/",
                image:this.getImage(0),
            },
            {
                id:1,
                link:"https://www.bioline.com/",
                image:this.getImage(1),
            },
            {
                id:2,
                link:"https://www.biosan.lv/fr",
                image:this.getImage(2),
            },
            {
                id:3,
                link:null,
                image:this.getImage(3),
            }
        ])
    }

    getImage(id){
        switch(id){
            case 0:{
                if(window.innerWidth > 768){return brooks_large}
                else if(window.innerWidth > 375){return brooks_medium}
                else{return brooks_small}
            }
            case 1:{
                if(window.innerWidth > 768){return bioline_large}
                else if(window.innerWidth > 375){return bioline_medium}
                else{return bioline_small}
            }
            case 2:{
                if(window.innerWidth > 768){return biosan_large}
                else if(window.innerWidth > 375){return biosan_medium}
                else{return biosan_small}
            }
            case 3:{
                if(window.innerWidth > 768){return seval_large}
                else if(window.innerWidth > 375){return seval_medium}
                else{return seval_small}
            }
        }
    }

    ///////////////
    // BROCHURES //
    ///////////////

    getBrochures(){
        return([
            {
                id:0,
                label:"Catalogue Bio-Technofix",
                image:btx_brochure_image,
                pdf:btx_brochure_pdf,
            },
            {
                id:1,
                label:"Catalogue Azenta",
                image:Azenta_brochure_image,
                pdf:Azenta_brochure_pdf,
            },
            {
                id:2,
                label:"Catalogue Biosan",
                image:biosan_brochure_image,
                pdf:biosan_brochure_pdf,
            },
            {
                id:3,
                label:"Catalogue Bioline",
                image:bioline_brochure_image,
                pdf:bioline_brochure_pdf,
            },
            {
                id:4,
                label:"Catalogue PHCBi",
                image:phcbi_brochure_image,
                pdf:phcbi_brochure_pdf,
            },
            {
                id:5,
                label:"Catalogue Brooks",
                image:brooks_brochure_image,
                pdf:brooks_brochure_pdf,
            }
        ])
    }

    renderBrochures(){
        return(
            <Paper style={{width:"100%", padding:12}}>
                <span style={{color:"#222222", fontSize:16, textAlign:"left", fontWeight:"bold", textTransform:"uppercase"}}>Nos catalogues</span>
                <p style={{color:"#555555", fontSize:14, textAlign:"left",  margin:0, marginTop:8,marginBottom:8}}>
                    Découvrez nos catalogues contenant tous nos produits, et nos promotions en cours :
                </p>
                <div style={{width:"100%", display:"flex", flexDirection:"row", overflowX:"auto", paddingBottom:4, paddingTop:4}}>
                    {this.state.brochures.map((brochure)=>{
                        return(
                            <Paper style={{width:250, overflow:"hidden", marginRight:12, position:"relative"}} key={"brochure_" + brochure.id}>
                                <img style={{width:250, height:250}} alt={"___"} src={brochure.image}/>
                                <div style={{width:"100%", height:40, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                backgroundColor:"#FAFAFA", borderTop:"1px solid #EFEFEF"}}>
                                    <p style={{color:"#222222", fontSize:12, textAlign:"left", paddingLeft:12, margin:0}}>
                                        {brochure.label}
                                    </p>
                                </div>

                                <a href={brochure.pdf} download>
                                    <div style={{position:"absolute", left:0, top:0, bottom:0, right:0, borderRadius:4, zIndex:15, height:"100%",
                                    width:"100%"}}/>
                                </a>
                            </Paper>
                        )
                    })}
                </div>
            </Paper>
        )
    }

    renderFeatured(){

        if(this.state.featuredArticles.length === 0){return null}
        return(
            <Paper style={{width:"100%", padding:12}}>
                <span style={{color:"#222222", fontSize:16, textAlign:"left", fontWeight:"bold", textTransform:"uppercase"}}>Produits à la Une</span>
                <p style={{color:"#555555", fontSize:14, textAlign:"left",  margin:0, marginTop:8,marginBottom:8}}>
                    Découvrez notre sélection de produits mis en avant:
                </p>
                <Paper style={{width:"100%"}}>
                    <ArticleList
                        articles={this.state.featuredArticles}
                        virtualized={true}
                        listHeight={this.state.featuredArticles.length >= 6 ? 600 : this.state.featuredArticles.length*100}
                    />
                </Paper>
            </Paper>
        )
    }
}
